:root {
  --primary-color: #AB000D;
  --primary-color-dark: #8E0000;
  --black: #000;
  --white: #fff;
  --grey: #605F5E;
  --light-grey: #777777;
}

body {
  background-image: url('src/shared/assets/pipes.png');
  overflow: auto;
  height: 100%;
  width: 100%;
  margin: 0;
}

*{
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

/* BASIC LAYOUT */
.smm__container {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.smm__container-wrapper__components {
  width: 100%;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.smm__container-wrapper__components__nav-component {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .smm__container {
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

@media (min-width: 1300px) {
  .smm__container-wrapper {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .smm__container-wrapper__components {
    width: 70%;
  }
  
}

