.logos {
  display: flex;
  justify-content: flex-end;
}

.logo_social_media {
  margin-left: 1rem;
}

.logo_social_media a{
  display: inline-block;
}

.logo_social_media img{
  display: block;
  cursor: pointer;
  transition: all;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition-duration: .15s;
}

.logo_social_media a:focus{
  outline: dashed black;
}

