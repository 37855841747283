.landing-wrapper {
  display: flex;
  flex-direction: column;
  /* margin-top: 1.5rem; */
  flex-grow: 1;
}

.landing-wrapper__first-item {
  display: block;
  width: 100%;
  text-align: center;
  max-width: 100%;
}

.landing-wrapper__second-item {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center;
  /* margin-top: 2rem; */
}

/* .landing-wrapper__first-item__img {
  display: none;
}

.landing-wrapper__second-item {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center;
  margin-top: 2rem;
}

.landing-wrapper__first-item__subtitle {
  font-weight: 800;
  letter-spacing: 10px;
  color: var(--grey);
  text-transform: uppercase;
  font-size: 1rem;
}

.landing-wrapper__first-item__title {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: 700;
}

.landing-wrapper__my-work-container__button {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-wrapper__second-item img{
  width: 320px;
} */

/* Tablet mode */
@media (min-width: 1300px) {
  .landing-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .landing-wrapper__first-item {
    max-width: 20rem;
  }

  /* .landing-wrapper__first-item__subtitle {
    font-size: 19px;
  }  

  .landing-wrapper__first-item__title {
    font-size: 50px;
  }

  .landing-wrapper__image-container img{
    width: 600px;
  }

  .landing-wrapper__first-item__img {
    display: initial;
  }

  .landing-wrapper__first-item__img img{
    margin-left: 4rem;
  } */
}