.element {
  height: 12rem;
  width: 18rem;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

.element__content {
  position: relative;
  z-index: 999;
  height: 100%;
  width: 100%;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
  /*display: none;
  */background: var(--primary-color);
  opacity: 0;
  transition: all ;
  -webkit-transition: all ;
  -moz-transition: all ;
  -ms-transition: all ;
  -o-transition: all ;
  transition-duration: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.element__content img {
  width: 50px;
  margin: 1rem;
}

.element:hover .element__content {
    opacity: .8;
    
}
