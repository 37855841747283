.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 1rem; */
}

.nav img {
  width: 100px;
}

.nav ul {
  list-style: none;
}

.nav li {
  display: inline-block;
  margin: 0.7rem 0.8rem;
}

.selected {
  outline: dashed var(--primary-color);
}

.nav a {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: var(--black);
  transition: all;
  transition-duration: 0.15s;
}

.nav a:hover {
  color: var(--primary-color);
}

.nav a:focus {
  outline: dashed var(--primary-color);
}

.nav .contact {
  display: none;
}

#contact-btn {
  text-decoration: none;
  background-color: var(--white);
  padding: 0.5rem 2rem;
  box-shadow: 1px 1px 0px 0px #ccc;
  border-radius: 999px;
  font-size: 1rem;
  color: var(--primary-color);
}

#contact-btn:focus {
  opacity: 1;
  outline: dashed var(--black);
}

#contact-btn:hover {
  background-color: #f5f5f5;
}

/* Tablet mode */
@media (min-width: 768px) {
  .nav {
    /* margin: 1rem 2rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
}

/* Desktop mode */
@media (min-width: 1300px) {
  .nav .contact {
    display: initial;
  }
}
