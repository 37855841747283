.small-text {
  font-size: 0.8rem;
}

.medium-text {
  font-size: 1rem;
}

.large-text {
  font-size: 1.5rem;
}

.bold {
  font-weight: 600;
}